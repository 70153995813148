<template>
  <div class="main-container container">
    <div class="row">
      <div class="col-12">
        <a
          class="twitter-timeline"
          href="https://twitter.com/RakshaUni?ref_src=twsrc%5Etfw"
          >Tweets by RakshaUni</a
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
    mounted () {
        let scriptTag = document.createElement('script')
        scriptTag.setAttribute('src', 'https://platform.twitter.com/widgets.js')
        document.body.appendChild(scriptTag);
    }
}
</script>